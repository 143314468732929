x
<template>
    <div class="bg-white rounded-sm mx-2 my-3">

        <b-row>
            <b-col>
                <EntityWrapper/>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import EntityWrapper from "@/views/home/services/registry/business/EntityWrapper";


export default {
    name: "BusinessRegistry",
    components: {EntityWrapper},
    data() {
        return {
            currentPath: this.$route.fullPath,
            items: [],

        }
    },
    mounted() {
        let paths = this.$route.path.split('/')
        let basePath = "/home/registry/"
        for (let i = 3; i < paths.length; i++) {
            basePath += paths[i]
            let path = {
                text: paths[i].toLowerCase().replaceAll("-", " "),
                href: basePath
            }
            this.items.push(path)
        }
    },
    computed: {
        ...mapGetters(["getRegistryError", "getEntityFile"])
    },
    methods: {
        ...mapActions(["uploadBatchBrnsForStrikingOff"]),

    },

    watch: {
        getEntityFile() {
            this.successToast("Success", "Added files to black list")
            window.location.reload();
        }
    }

}
</script>

<style scoped>
@import "../../../../assets/styles/menu.css";

</style>