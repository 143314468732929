import Vue from "vue";
import VueRouter from "vue-router";
import Error403 from "@/views/error/Error403";
import Error404 from "@/views/error/Error404";
import abilities from "@/abilities";
import Home from "@/views/home/Home";
import Auth from "@/views/auth/Auth";
import PasswordReset from "@/views/auth/PasswordReset/PasswordReset";
import Login from "@/views/auth/Login/Login";
import Accounts from "@/views/home/services/accounts/Accounts";
import URSBRegistry from "@/views/home/services/registry/URSBRegistry";
import BusinessRegistry from "@/views/home/services/registry/BusinessRegistry";
import DocumentRegistry from "@/views/home/services/registry/DocumentRegistry";
import PractitionerRegistry from "@/views/home/services/registry/PractitionerRegistry";
import EntityFileWrapper from "@/views/home/services/registry/business/files/EntityFileWrapper";
import LegalDocumentFileWrapper from "@/views/home/services/registry/legal-documents/LegalDocumentFileWrapper";
import PractitionerProfileWrapper from "@/views/home/services/registry/practitioners/PractitionerProfileWrapper";
import AccountBreakdown from "@/views/home/services/accounts/breakdown/AccountBreakdown";
import PermissionsManagement from "@/views/home/services/accounts/permissions/PermissionsManagement";
import Settings from "@/views/home/services/settings/Settings";
import ServiceCost from "@/views/home/services/billing/ServiceCost";
import Reports from "@/views/home/services/reports/Reports";
import URSBTaxHeads from "@/views/home/services/billing/URSBTaxHeads";
import BillingAndRevenue from "@/views/home/services/billing/BillingAndRevenue";
import SystemCredentials from "@/views/home/services/integrations/SystemCredentials.vue";
import Configurations from "@/views/home/services/configurations/Configurations";
import InternalProcesses from "@/views/home/services/internal_processing/InternalProcesses";
import PRNManager from "@/views/home/services/billing/prn-manager/PRNManager";
import DocumentCost from "@/views/home/services/billing/DocumentCost.vue";
import Transactions from "@/views/home/services/billing/Transactions.vue";
import APIIntegrators from "@/views/home/services/integrations/APIIntegrators.vue";
import Integrations from "@/views/home/services/integrations/Integrations.vue";
import HomeDashboard from "@/views/home/services/HomeDashboard.vue";
import MDARegistry from "@/views/home/services/registry/MDARegistry.vue";
import AccountSummary from "@/views/home/services/accounts/breakdown/AccountSummary.vue";
import InsolvencySettings from "@/views/home/services/settings/InsolvencySettings.vue";

Vue.use(VueRouter);
let router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Auth,
      redirect: '/login',
      name:"login",
      meta:{
        title:"Login"
      },
      children: [
        {
          path: '/login',
          component: Login,
          name:"login-page"
        },
        {
          path: '/reset-password',
          component: PasswordReset,
          name:"reset-password"
        },
      ]
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: Error403,
      meta: {
        title: "Unauthorized",
        requiresAuth: true,
        resource: "forbidden",
        action: "read"
      },
    },

    {
      path: "/home",
      component: Home,
      redirect: "/home/dashboard",
      name: "Home",
      meta: {
        requiresAuth: true,
        resource: "dashboard",
        action: "can_access_dashboard",
      },
      children:[
        {
          name: "user_profile",
          path: "/home/dashboard",
          component: HomeDashboard,
          meta: {requiresAuth: true, title: "", resource: "general", action: "can_access_dashboard"}
        },
        {
          path: "/home/accounts",
          name:"accounts",
          component: Accounts,
          redirect:"/home/accounts/breakdown",
          meta: {requiresAuth: true, resource: "general", action: "can_access_system_accounts",},
          children:[
            {
              path:"/home/accounts/breakdown",
              component:AccountBreakdown,
              name: "accounts-breakdown",
              meta: {action: "can_view_accounts_breakdown", resource: "general",},
            },
            {
              path: "/home/accounts/summary",
              component: AccountSummary,
              name: "account-summary",
              meta: {action: "can_view_accounts_breakdown", resource: "general",},
            },
            {
              path:"/home/accounts/permissions",
              component:PermissionsManagement,
              name: "permission-breakdown",
              meta: {action: "can_view_permission_management", resource: "general",},
            },
          ]
        },
        {
          path: "/home/registry",
          name:"registry",
          component: URSBRegistry,
          meta: {
            requiresAuth: true, action: "can_view_registry",
            resource: "general",},
          children:[
            {
              path:"/home/registry/business",
              component:BusinessRegistry,
              name:"business-registry",
              meta: {
                title: "Business Registry", action: "can_view_blacklist_registry",
                resource: "general",},
            },
              {
                  path: "/home/registry/mda",
                  component: MDARegistry,
                  name: "mda-registry",
                  meta: {
                      title: "MDA Registry", action: "can_view_blacklist_registry",
                      resource: "general",
                  },
              }, {
              path: "/home/registry/de-registration",
              component: BusinessRegistry,
              name: "de-registration-registry",
              meta: {
                title: "De registration Registry", action: "can_view_blacklist_registry",
                resource: "general",
              },
            },
            {
              path:"/home/registry/document",
              component:DocumentRegistry,
              name:"document-registry",
              meta: {
                title: "Document Registry", action: "can_view_doc_registry",
                resource: "general",}
            },
            {
              path:"/home/registry/practitioners",
              component:PractitionerRegistry,
              name:"practitioners-registry",
              meta: {
                title: "Practitioner Registry", action: "can_view_practitioner_registry",
                resource: "general",}
            },
            {
              path:"/home/registry/file-viewer/:id",
              component:EntityFileWrapper,
              name:"file-viewer",
              meta:{title:"File - URSB Registry", action: "can_view_reservation_settings",
                resource: "general",}
            },
            {
              path:"/home/registry/document-viewer/:id",
              component:LegalDocumentFileWrapper,
              name:"document-viewer",
              meta:{title:"Document - URSB Registry", action: "can_view_reservation_settings",
                resource: "general",}
            },
            {
              path:"/home/registry/practitioner-viewer/:id",
              component: PractitionerProfileWrapper,
              name: "practitioner-viewer",
              meta: {
                title: "Practitioner - URSB Registry", action: "can_view_reservation_settings",
                resource: "general",
              }
            },
          ]
        },
        {
          path: "/home/billing",
          name: "billing",
          component: BillingAndRevenue,
          meta: {requiresAuth: true, resource: "dashboard", action: "can_access_billing_settings",},
          children: [
              {
                  path: "/home/billing/service-costs",
                  component: ServiceCost,
                  name: "service-costs",
                meta: {action: "can_access_billing_settings", resource: "general",},
              },
              {
                  path: "/home/billing/document-costs",
                  component: DocumentCost,
                  name: "document-costs",
                meta: {action: "can_access_billing_settings", resource: "general",},
              },
              {
                  path: "/home/billing/tax-heads",
                  component: URSBTaxHeads,
                  name: "tax-heads",
                meta: {action: "can_access_billing_settings", resource: "general",},
              },
              {
                path: "/home/billing/transactions",
                component: Transactions,
                name: "transactions",
                meta: {action: "can_access_billing_settings", resource: "general",},
              },
              {
                  path: "/home/billing/prn-manager",
                  component: PRNManager,
                  name: "prn-manager",
                meta: {action: "can_access_billing_settings", resource: "general",},
            },
          ]
        },
        {
          path: "/home/integrations",
          name: "integrations",
          component: Integrations,
          meta: {requiresAuth: true, resource: "dashboard", action: "can_access_integration_settings",},
          children:[
            {
              path: "/home/integrations/credentials",
              name: "integrations-credentials",
              component: SystemCredentials,
              meta: {requiresAuth: true, resource: "dashboard", action: "can_access_system_credentials",},
            },
            {
              path: "/home/integrations/mdas_and_partners",
              name: "integrations-mdas-and-partners",
              component: APIIntegrators,
              meta: {requiresAuth: true, resource: "dashboard", action: "can_access_api_integrations",},
            },
          ]
        },
        {
          path: "/home/settings",
          name:"settings",
          component: Settings,
          meta: {requiresAuth: true, resource: "dashboard", action: "can_access_settings",},
        }, {
          path: "/home/insolvency-settings",
          name: "insolvency-settings",
          component: InsolvencySettings,
          meta: {requiresAuth: true, resource: "dashboard", action: "can_access_settings",},
        },
        {
          path: "/home/configurations",
          name:"configurations",
          component: Configurations,
          meta: {requiresAuth: true, resource: "dashboard", action: "can_access_configurations",},
        },
        {
          path: "/home/internal-processes",
          name:"internal-processes",
          component: InternalProcesses,
          meta: {requiresAuth: true, resource: "dashboard", action: "can_access_internal_processing",},
        },
        {
          path: "/home/reports",
          name:"reports",
          component: Reports,
          meta: { requiresAuth: true, resource: "dashboard", action: "can_access_dashboard", },
        }
      ]
    },
    {
      path: "/error-404",
      component: Error404,
      name: "error-404",
      meta: {
        title: "Error-404 ",
      },
    },
    {
      path: "*",
      redirect: {name: "error-404"},
    },
  ]
});

let current_user = sessionStorage.getItem("current_user");


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (current_user === null) {
      sessionStorage.clear();

      next({
        name: "login",
        params: {nextUrl: to.fullPath},
      });
    } else {
      const canNavigate = to.matched.some((route) => {
        return abilities.can(route.meta.action, route.meta.resource);
      });

      if (!canNavigate) {
        abilities.update([{action: "read", subject: "forbidden"}]);
        return next({
          name: "forbidden",
        });

      }

      let user = JSON.parse(current_user);
      if (user !== null) {
        next();
      } else {
        next({name: "Home"});
      }
    }
  } else {
    if (current_user === null) {
      sessionStorage.clear()
      next();
    } else {
      next({
        name: "Home",
      });
    }
  }
});

const DEFAULT_TITLE = "OBRS Admin Portal";
router.afterEach(() => {
  Vue.nextTick(() => {
    document.title =  "OBRS Admin Portal" || DEFAULT_TITLE;
  });
});

export default router;

