<template>
    <b-row>
        <b-col>
            <b-row v-if="application">

                <b-row>
                    <b-modal title="Application Details" ref="applicationDetails" size="xl" hide-footer>
                        <WindingInsolvencyDetails :tracking-number="trackingNumber"/>
                    </b-modal>
                </b-row>
                <b-modal ref="updateDatePopup" title="Update date of publication" hide-footer size="xl">
                    <b-row>
                        <b-col cols="12" sm="12" md="12" lg="6">
                            <label class="text-gray mb-1" style="font-size: 14px;">New date of publication</label>
                            <datepicker
                                    :disabled-dates="{from:new Date()}"
                                    class="noFocus text-sm"
                                    :typeable="false"
                                    bootstrap-styling
                                    id="dateOfBirth"
                                    format="dd/MM/yyyy"
                                    placeholder="dd/mm/yyyy"
                                    v-model="updateForm.newDate"
                            ></datepicker>


                        </b-col>
                        <b-col>
                            <label class="text-sm">Update Dissolution date ? </label>
                            <b-form-select class="form-select mb-2" v-model="updateForm.updateDateOfDissolution"
                                           :options="yesNo"></b-form-select>
                        </b-col>

                        <InsolvencyFormFiles v-on:attachment="setAttachmentId" :task="application"
                                             v-if="application.attachments.length  > 0"/>

                        <b-row>
                            <b-col cols="7">

                            </b-col>
                            <b-col>
                        <button @click="updateDateOfPublication"
                                class="bg-blue text-white px-3 py-1 rounded-sm mt-2 float-end" type="button">
                            Update
                            <b-spinner v-if="loading" small></b-spinner>
                        </button>
                            </b-col>
                        </b-row>

                    </b-row>

                </b-modal>

            </b-row>
            <b-row>
                <b-col cols="3">
                    <b-form-input
                            class="text-gray text-sm noFocus mx-2 my-2"
                            style="padding:6px 5px;"
                            v-model="search"
                            type="text"
                            placeholder="Search by BRN, TrackingNo"
                            required
                            v-on:keyup.enter="fetch">
                    </b-form-input>
                </b-col>
                <b-col cols="2">
                    <b-form-group>
                        <b-form-select
                                v-model="statuses"
                                v-on:change="fetch"
                                class="form-select noFocus text-gray mx-2 my-2 text-sm">
                            <b-select-option value="APPROVED">Approved</b-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="2">
                </b-col>
                <b-col cols="2">

                </b-col>
                <b-col cols="1">
                    <b-form-select v-model="limit" class="form-select w-full mt-2 text-xs paginate-details"
                                   v-on:change="fetch">
                        <b-select-option value="12">12</b-select-option>
                        <b-select-option value="20">20</b-select-option>
                        <b-select-option value="50">50</b-select-option>
                        <b-select-option value="100">100</b-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="2">
                    <b-row>
                        <b-col class="mx-auto mt-3">
                            <p class="paginate-details text-center text-xs font-bold">
                                <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                                <span v-if="limit > submissions.length ">{{ pagination.totalElements }}</span>
                                <span v-else>{{ currentPage * limit }}</span>
                                of {{ pagination.totalElements }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>


            </b-row>
            <div class="mx-auto">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row>
                <b-col cols="12">

                    <table class="min-w-full leading-normal">
                        <thead>
                        <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">Tracking No.</th>
                            <th class="t-header">BRN & Name</th>
                            <th class="t-header">Type</th>
                            <th class="t-header text-center">Status</th>
                            <th class="t-header text-center">Assignment</th>
                            <th class="t-header text-center text-xs">Last Date Of Publication</th>
                            <th class="t-header text-center text-xs">Date Of Confirmation</th>
                            <th class="t-header text-center">Dissolution Date</th>
                            <th class="t-header text-center">Action</th>

                        </tr>
                        </thead>

                        <tbody>
                        <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions"
                            :key="idx">
                            <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
                            <td class="t-body text-blue font-bold cursor-pointer hover:underline"
                                @click="showDetails(submission.trackingNumber)">{{ submission.trackingNumber }}
                            </td>
                            <td class="t-body text-black font-bold hover:underline"
                            >
                                {{ submission.brn }} <br/>
                                {{ submission.companyName }}
                            </td>
                            <td class="t-body font-bold">{{ cleanUnderscores(submission.type) }}
                                <span v-if="submission.type==='WINDING_UP'">[{{ submission.windingUpType }}]</span>
                            </td>
                            <td class="t-body">
                                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                <span :class="(submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                              (submission.status.toUpperCase() === 'PAYMENT_FAILED') ? 'text-red' :
                              (submission.status.toUpperCase() === 'REJECTED') ? 'text-red' :
                              (submission.status.toUpperCase() === 'AWAITING_PAYMENT') ? 'text-purple' :
                              (submission.status.toUpperCase() === 'SUBMITTED') ? 'text-gray' :
                              (submission.status.toUpperCase() === 'ASSIGNED')? 'text-blue':
                              (submission.status.toUpperCase() === 'APPROVED') ? 'text-green' :
                              (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' : ''">
                  <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT'">
                      Confirming Payment
                  </span>
                  <span v-else-if="submission.status.toUpperCase() === 'SUBMITTED'">
                      In Queue
                    </span>
                  <span v-else>
                    {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                  </span>
                </span>
                                </p>
                            </td>
                            <td class="t-body text-center text-capitalize">
                                <span class="text-xs"
                                      v-if="submission.registrarName">{{ submission.registrarName.toLowerCase() }}</span>
                                <span class="text-xs" v-else>Not Assigned</span>
                            </td>

                            <td class="t-body text-center">
                                {{ dateFormat(submission.lastDateOfPublication) }}
                                <button
                                        @click="showUpdateForm(submission,idx)"
                                        type="button"
                                        class=" text-blue text-xs border-blue  draft-btn mx-1  w-6/12">
                                    <i class="fa fa-pencil"></i> Edit
                                    <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                                </button>
                            </td>
                            <td class="t-body text-center">
                                {{ dateFormat(submission.dateOfConfirmation) }}
                            </td>

                            <td class="t-body text-center">
                                {{ dateFormat(submission.dateOfDissolution) }}
                            </td>

                            <td class="t-body text-center">

                                <button
                                        @click="abortApplication(submission.trackingNumber,idx)"
                                        type="button"
                                        class="text-xs border-1 border-red p-1 rounded-2">
                                    <i class="bg-white text-red fa fa-undo"></i> Rollback
                                    <b-spinner v-if="abortLoadingIndex===idx" small></b-spinner>
                                </button>


                            </td>

                        </tr>
                        <tr>
                            <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                                <p class="text-sm">Failed to load tasks, Try again later.</p>
                                <p class="text-sm">{{ errorMessage }}</p>
                                <p class="text-sm">
                                    <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload
                                        <b-spinner v-if="loading" small></b-spinner>
                                    </button>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
                <b-col cols="1">
                    <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
                </b-col>
                <b-col cols="1">
                    <b-row>
                        <b-col cols="12">
                            <p class="paginate-details text-center">
                                <b-badge class="bg-blue text-white">
                                    {{ currentPage }}/{{ pagination.totalPages }}
                                </b-badge>
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="1">
                    <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
                        Next
                    </button>
                </b-col>
                <b-col cols="6"></b-col>
                <b-col cols="1">
                    <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details"
                                   v-on:change="fetch">
                        <b-select-option value="12">12</b-select-option>
                        <b-select-option value="20">20</b-select-option>
                        <b-select-option value="50">50</b-select-option>
                        <b-select-option value="100">100</b-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="2">
                    <b-row>
                        <b-col class="mx-auto">
                            <p class="paginate-details text-center text-xs font-bold">
                                <span v-if="currentPage === 1">1</span>
                                <span v-else>{{ ((currentPage - 1) * limit) + 1 }}</span> - {{ currentPage * limit }} of
                                {{ pagination.totalElements }}
                            </p>

                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";

import Datepicker from "vuejs-datepicker";

import moment from "moment/moment";
import InsolvencyFormFiles from "@/views/home/services/settings/insolvency/InsolvencyFormFiles.vue";
import WindingInsolvencyDetails from "@/views/home/services/settings/insolvency/WindingInsolvencyDetails.vue";

export default {
    name: "AllInsolvencySubmissions",
    components: {WindingInsolvencyDetails, InsolvencyFormFiles, Datepicker},
    data() {
        return {
            loading: false,
            loadingMoveTask: false,
            loadingRevertTask: false,
            currentPage: 1,
            limit: 12,
            submissions: [],
            pagination: {
                totalElements: 0,
                totalPages: 0
            },
            statuses: "APPROVED",
            taskId: null,
            level: null,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey bg-header_grey"
            },
            search: "",
            application: null,
            application_prn: null,
            hasError: false,
            errorMessage: null,
            loadingIndex: null,
            abortLoadingIndex: null,
            trackingNumber: null,
            updateForm: {
                attachmentId: null,
                newDate: null,
                updateDateOfDissolution: false,
            },
            yesNo: [
                {
                    value: true,
                    text: "Yes"
                },
                {
                    value: false,
                    text: "No"
                }
            ],
            pdfUrl: null
        };
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapActions(["fetchInsolvency", "fetchAllInsolvency", "fetchInsolvencyStatistics"]),
        fetch() {


            let data = {
                status: this.statuses,
                search: this.search,
                page: this.currentPage,
                pageSize: this.limit,
                type: "WINDING_UP,FOREIGN_COMPANY_DE_REGISTRATION"
            };
            this.loading = true;
            let url = Object.entries(data)
                .filter(([, value]) => value !== null && value !== "")
                .map(([key, value]) => `${key}=${value}`)
                .join("&");

            url = `?${url}`;
            this.fetchAllInsolvency(url);

        },

        showDetails(trackingNumber) {
            this.$emit("openDetails", trackingNumber);
        },
        next() {
            if (this.currentPage < this.pagination.totalPages) {
                this.currentPage++;
                this.fetch();
            }
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetch();
            } else {
                this.warningToast("Observation", "No more data to show");
            }
        },
        abortApplication(trackingNumber, idx) {


            this.showConfirmDialog("Are you sure that you want to roll back this application ?").then(value => {
                if (value) {
                    this.loading = true;
                    this.abortLoadingIndex = idx;
                    api().post("/insolvency/process/rollback/" + trackingNumber)
                        .then(() => {
                            this.successToast("Success", "Application rolled back successfully");
                            this.fetch();
                            this.abortLoadingIndex = null
                        })
                        .catch((error) => {
                            this.errorToast("Error", error.response.data.message);
                            this.abortLoadingIndex = null
                        });

                }
            });


        },
        async fetchInsolvency(application) {
            let trackingNumber = application.trackingNumber;
            let path = "insolvency/winding-up/details?tracking-number=" + trackingNumber
            if (application.type === "FOREIGN_COMPANY_DE_REGISTRATION") {
                path = "insolvency/process/by-tracking-no?trackingNumber=" + trackingNumber
            }


            api().get(path).then(response => {
                this.application = response.data.data
                this.$refs.updateDatePopup.show()

            }).catch(() => {
                //this.errorToast("Error", JSON.stringify(error));
            }).finally(() => {
                this.$refs.updateDatePopup.show()
                this.loadingIndex = null;
            });
        },
        showUpdateForm(application, idx) {
            this.loadingIndex = idx;
            this.fetchInsolvency(application);

        },
        updateDateOfPublication() {
            this.loading = true;

            this.updateForm.newDate = moment(this.updateForm.newDate).format("YYYY-MM-DD");
            api().post("/insolvency/process/fix-last-date-of-publication", this.updateForm)
                .then(() => {
                    this.loading = false;
                    this.successToast("Success", "Successfully updated date of publication");
                    window.location.reload();
                })
                .catch(() => {
                    this.loading = false;
                    this.errorToast("Error", "Failed to update date of publication");
                });
        },
        setAttachmentId(attachment) {
            this.updateForm.attachmentId = attachment.id;
            this.updateForm.newDate = attachment.dateOfPublication;
        },


    },
    computed: {
        ...mapGetters([
            "getInsolvencySubmissions", "getInsolvencyError"]),
        hasAction() {
            return this.status === null || (this.status !== "QUERIED" && this.status !== "APPROVED");
        }
    },
    watch: {
        getInsolvencySubmissions(data) {
            this.loading = false;
            this.submissions = data.content;
            this.pagination = {
                totalPages: data.totalPages,
                totalElements: data.totalElements
            };

        },
        getInsolvencyError(data) {
            this.loading = false
            this.errorToast("Error", data.messages)
        }
    }
};
</script>

<style scoped>

.paginate-details {
    @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}


.btn-certificate {
    @apply border border-green rounded-md text-green  px-1 py-1 bg-white;
}

.btn-certificate:hover {
    @apply text-green-dark;
}

</style>