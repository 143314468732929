<template>
    <div>
        <b-row>
            <b-col cols="4" class="my-3">

                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Attachments</td>
                    </tr>
                    <tr>
                        <th class="px-2 py-1">#</th>
                        <th class="px-2 py-1">File Name</th>
                        <th class="px-2 py-1">Status</th>
                        <th class="px-2 py-1 float-end">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file, idx) in attachments" :key="idx" style="border: solid 1px #dedede;">
                        <td class="px-2 py-1">{{ idx + 1 }}</td>
                        <td class="px-2 py-1 text-capitalize">{{
                                cleanUnderscores(file.fileDescription)

                            }}
                        </td>
                        <td class="px-2 py-1 text-capitalize">
                            <i :class="file.status ==='APPROVED' ? 'fas fa-eye text-green' : 'fas fa-eye-slash text-gray'"
                               style="cursor: pointer"></i>
                        </td>
                        <td class="px-2 py-1">
                            <button class="float-end bg-gray rounded text-white hover:bg-blue-dark px-2"
                                    @click="openFile(file)">Select file
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </b-col>
            <b-col cols="8">
                <label class="text-blue">{{ cleanUnderscores(attachmentType) }}</label>
                <InsolvencyAttachments ref="attachmentScreen"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import server from "@/app/env/server.json";
import InsolvencyAttachments from "@/views/home/services/settings/insolvency/InsolvencyAttachments.vue";


export default {
    name: "InsolvencyFormFiles",
    components: {InsolvencyAttachments},
    props: {
        task: Object
    },
    data() {
        return {
            attachmentUrl: null,
            attachmentType: null,
            attachmentId: null,
            attachments: []
        }
    },
    mounted() {
        this.openFile(this.task.attachments[0])
        // filter attachments that have fileDescription that starts with the word  newspaper or starts with gazetted ignore casing
        let newspaper = this.task.attachments.filter(attachment => attachment.fileDescription.toLowerCase().startsWith('newspaper'))
        let gazetted = this.task.attachments.filter(attachment => attachment.fileDescription.toLowerCase().startsWith('gazetted'))
        //  console.log(newspaper, gazetted)
        //merge the two arrays
        this.attachments = [...newspaper, ...gazetted]

    },
    methods: {
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        openFile(data) {
            if (data) {
                let fName = data ? data.fileName.trim() : ""
                this.attachmentUrl = server.IP + "/insolvency/files?attachment=" + fName
                this.attachmentType = data.type.trim()
// emit attachment id to parent component
                this.$emit('attachment', data)

            }
        },
    },
    watch: {
        attachmentUrl: {
            handler(url) {
                console.log(url)
                this.$refs.attachmentScreen.openFile(url)
            },
            deep: true
        }
    }
}
</script>

<style scoped>
th, td {
    font-size: 12px;
}
</style>