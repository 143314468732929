<template>
    <div class="overflow-hidden bg-gray p-2">
        <b-row class="my-2">
            <b-col cols="12">
                <iframe v-if="url" class="pdf" :src="url"></iframe>
                <p v-else class="bg-gray min-h-screen text-center">
                    <span class="py-5 text-white">Select a document to preview</span>
                </p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "InsolvencyAttachments",
    data() {
        return {
            loading: false,
            url: null
        }
    },
    methods: {
        openFile(url) {
            this.url = url
        }
    },
}
</script>

<style scoped>
.pdf {
    width: 100%;
    height: 800px;
    overflow-x: auto;
    border: 1px solid #b1b1b1;
    top: 0 !important;
}
</style>